// We assume that names of the categories are "ostprodukter" and "farskvaror"
// TODO: Add check for new products and products with discount
const formatCategory = (category) => {
  const materialContext = category === 'ostprodukter' ? 'cheese' : 'fresh'
  return `isNotNew/hasNoDiscount/${materialContext}`
}

// These are hardcoded wholesaler values for Connect "wholesaler"
const wholesalerConnectName = 'Arla Connect'
const wholesalerConnectId = '64a2e32ba652fe7c9ca9e5f1'

export default {
  recipeTabsIngredient_changeAmountByInput(portions) {
    const pushedData = {}
    pushedData.event = 'recipeScalingByInputField'
    pushedData.amount = portions
    this.pushDataLayer(pushedData)
  },

  showMoreInfo(listName) {
    const pushedData = {
      event: 'showMoreInfo',
      listName
    }
    this.pushDataLayer(pushedData)
  },

  showLessInfo(listName) {
    const pushedData = {
      event: 'showLessInfo',
      listName
    }
    this.pushDataLayer(pushedData)
  },

  productImpression(product) {
    const gtmObj = {
      event: 'eec.impressionView',
      ecommerce: {
        impressions: [{
          name: product.name,
          id: product.code,
          category: formatCategory(product.category),
          position: product.position,
          variant: product.weightAndVolume,
          list: product.list
        }]
      }
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  productClicks(product) {
    const gtmObj = {
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: product.list },
          products: [{
            name: product.name,
            id: product.code,
            category: formatCategory(product.category),
            position: product.position,
            variant: product.weightAndVolume
          }]
        }
      }
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  productLoginCTA({
    productId,
    productName,
    placement,
    categoryName,
    brandName
  }) {
    const wholesalerName = wholesalerConnectName
    const wholesalerId = wholesalerConnectId

    this.pushDataLayer({
      event: 'productLoginCTA',
      productId,
      productName,
      placement,
      wholesalerName,
      wholesalerId,
      categoryName,
      brandName
    })
  },

  productAddToCart({
    productName,
    productAmount,
    unitSelection,
    placement,
    productId,
    categoryName,
    brandName
  }) {
    const wholesalerName = wholesalerConnectName
    const wholesalerId = wholesalerConnectId
    const gtmObj = {
      event: 'buyButtonAddToBasket',
      productName,
      productAmount,
      unitSelection,
      placement,
      productId,
      wholesalerName,
      wholesalerId,
      categoryName,
      brandName
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  productAddToCartError({ productName, errorMessage }) {
    const gtmObj = {
      event: 'buyButtonError',
      productName,
      errorMessage
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  materialAddToCart(blockName, amount) {
    const gtmObj = {
      event: 'material-addToCart',
      blockName,
      amount
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  materialChangeAmount(blockName, changeAmount) {
    const gtmObj = {
      event: 'materialChangeAmount',
      blockName,
      changeAmount
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  materialCheckoutChangeAmount(blockName, changeAmount) {
    const gtmObj = {
      event: 'materialCheckoutChangeAmount',
      blockName,
      changeAmount
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  materialRemoveFromCart(blockName, amount) {
    const gtmObj = {
      event: 'material-deleteFromCart',
      blockName,
      amount
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  materialGoToCheckout(totalAmount) {
    const gtmObj = {
      event: 'material-goToCheckOut',
      totalAmount
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  backToAllMaterials() {
    // push to gtm
    this.pushDataLayer({ event: 'material-backToAllMaterials' })
  },

  materialOrder(totalAmount) {
    const gtmObj = {
      event: 'material-Order',
      totalAmount
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  confirmGoToOrderButton() {
    const gtmObj = {
      event: 'confirmGoToOrderButton'
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  recipeClicks(recipe) {
    const gtmObj = {
      event: 'recipeClick',
      recipeName: recipe.name,
      recipeList: recipe.list
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  recipeBusinessCardClicks(recipe) {
    const gtmObj = {
      event: 'recipeBusinessCardClick',
      recipeName: recipe.name,
      recipeList: recipe.list
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  teaserClick({ teaserType, title }) {
    const gtmObj = {
      event: 'teaserClick',
      teaserType,
      title
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  articleClicks(article) {
    const gtmObj = {
      event: 'articleClick',
      articleName: article.name,
      articleList: article.list
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  businessCaseClicks(bc) {
    const gtmObj = {
      event: 'businessCaseClick',
      businessCaseName: bc.name,
      businessCaseList: bc.list
    }

    // push to gtm
    this.pushDataLayer(gtmObj)
  },

  addSearchTerm([...terms], term, placement = '') {
    const pushedData = {
      event: 'addSearchTerm',
      term,
      terms,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  removeSearchTerm([...terms], term, placement = '') {
    const pushedData = {
      event: 'removeSearchTerm',
      term,
      terms,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  removeTermsAndFilters(terms, filters, placement = '') {
    const pushedData = {
      event: 'removeTermsAndFilters',
      terms,
      filters,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  changeSorting(type, placement = '') {
    const pushedData = {
      event: 'sorting',
      type,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  changeView(type, placement = '') {
    const pushedData = {
      event: 'itemsViewTypeClick',
      type,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  overviewContentTabClick(type, placement = '') {
    const pushedData = {
      event: 'overviewContentTabClick',
      type,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  addFilter([...filters], filter, placement = '') {
    const pushedData = {
      event: 'addFilter',
      filter,
      filters,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  removeFilter([...filters], filter, placement = '') {
    const pushedData = {
      event: 'removeFilter',
      filter,
      filters,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  removeAllFilter([...filters], placement = '') {
    const pushedData = {
      event: 'removeAllFilter',
      filters,
      placement
    }

    this.pushDataLayer(pushedData)
  },

  changeInspirationTag({ tagName, currentSelection }) {
    this.pushDataLayer({
      event: 'clickTagInspiration',
      tagName,
      currentSelection
    })
  },

  expandBlock(blockName) {
    this.pushDataLayer({
      event: 'expandBlock',
      blockName
    })
  },

  shrinkBlock(blockName) {
    this.pushDataLayer({
      event: 'shrinkBlock',
      blockName
    })
  },

  printBusinessCase(caseName) {
    this.pushDataLayer({
      event: 'printBusinessCase',
      caseName
    })
  },

  addToFavorites({ id, name, type }) {
    if (type === 'product') {
      this.pushDataLayer({
        event: 'addToFavorites',
        productId: id,
        productName: name
      })
    }
    if (type === 'recipe') {
      this.pushDataLayer({
        event: 'addRecipeToFavorites',
        addedFavoriteRecipe: name
      })
    }
    if (type === 'businesscase') {
      this.pushDataLayer({
        event: 'addedFavouriteBusinessCase',
        businessCaseName: name
      })
    }
  },

  removeFromFavorites({ id, name, type }) {
    if (type === 'product') {
      this.pushDataLayer({
        event: 'removeFromFavorites',
        productId: id,
        productName: name
      })
    }
    if (type === 'recipe') {
      this.pushDataLayer({
        event: 'removeFavorites',
        removedFavoriteRecipe: name
      })
    }
    if (type === 'businesscase') {
      this.pushDataLayer({
        event: 'removedFavouriteBusinessCase',
        businessCaseName: name
      })
    }
  },

  changeSlide(direction, cardType = '', listName = '') {
    this.pushDataLayer({
      event: 'switchContentCard',
      cardType,
      listName,
      direction
    })
  },

  loginConnect() {
    this.pushDataLayer({
      event: 'logIn',
      loginType: 'connect'
    })
  },

  logOut(loginType) {
    this.pushDataLayer({
      event: 'topMenuLogoutButton',
      loginType
    })
  },

  logInChooseType(loginType) {
    this.pushDataLayer({
      event: 'logInChooseType',
      loginType
    })
  },

  logInSUPButtonClick(buttonName, loginType) {
    this.pushDataLayer({
      event: 'logInButtonClick',
      buttonName,
      loginType
    })
  },

  logInSwitchType(linkName, loginType) {
    this.pushDataLayer({
      event: 'logInSwitchType',
      linkName,
      loginType
    })
  },

  showHeaderSidebar() {
    this.pushDataLayer({
      event: 'topMenuLoginButton'
    })
  },

  mainMenuItemClick(event, topMenuTitleText) {
    this.pushDataLayer({
      event,
      topMenuTitleText
    })
  },

  mainMenuSectionTitleClick(topMenuSectionTitleText) {
    this.pushDataLayer({
      event: 'topMenuSectionTitleLinkClick',
      topMenuSectionTitleText
    })
  },

  mainMenuDropdownLinkClick(topMenuDropdownTitleText) {
    this.pushDataLayer({
      event: 'topMenuDropdownTitleLinkClick',
      topMenuDropdownTitleText
    })
  },

  mainMenuDropdownSeeMoreTextClick(topMenuDropdownTitleText) {
    this.pushDataLayer({
      event: 'topMenuDropdownSeeMoreLinkClick',
      topMenuDropdownTitleText
    })
  },

  mainMenuDropdownVisualCTATextClick(topMenuDropdownVisualCTAText) {
    this.pushDataLayer({
      event: 'topMenuDropdownVisualCTAClick',
      topMenuDropdownVisualCTAText
    })
  },

  mainMenuNavigationOpen() {
    this.pushDataLayer({
      event: 'menuNavigationOpen'
    })
  },

  mainMenuNavigationClose() {
    this.pushDataLayer({
      event: 'menuNavigationClose'
    })
  },

  mainMenuPreviousClick() {
    this.pushDataLayer({
      event: 'menuNavigationPrevious'
    })
  },

  mainMenuSearchClick() {
    this.pushDataLayer({
      event: 'topMenuSearchClick'
    })
  },

  mainMenuTypeSearchTerm(searchTerm) {
    this.pushDataLayer({
      event: 'topMenuSearchTerm',
      searchTerm
    })
  },

  mainMenuSearchItemClick({
    searchItemTitle,
    selectionType,
    searchQuery
  }) {
    this.pushDataLayer({
      event: 'searchItemClick',
      searchItemTitle,
      selectionType,
      searchQuery
    })
  },

  openWholesalerModal({ productID, productName, placement }) {
    this.pushDataLayer({
      event: 'wtbOpenOverlay',
      productID,
      placement,
      productName
    })
  },

  wholesalerClick({
    wholesalerName,
    wholesalerId = '',
    productId,
    productName,
    placement,
    categoryName,
    brandName
  }) {
    this.pushDataLayer({
      event: 'wholesalerClick',
      wholesalerName,
      wholesalerId,
      productId,
      productName,
      placement,
      categoryName,
      brandName
    })
  },

  infoPopupOpen({
    popupTitle,
    popupType,
    isSignedUp
  }) {
    this.pushDataLayer({
      event: 'popupOpen',
      popupTitle,
      popupType,
      isSignedUp
    })
  },

  infoPopupClose({
    popupTitle,
    popupType,
    isSignedUp
  }) {
    this.pushDataLayer({
      event: 'popupClose',
      popupTitle,
      popupType,
      isSignedUp
    })
  },

  infoPopupCtaClick({
    popupTitle,
    popupType,
    isSignedUp
  }) {
    this.pushDataLayer({
      event: 'popupCtaClick',
      popupTitle,
      popupType,
      isSignedUp
    })
  },

  addToCatalogue({ itemName, itemType }) {
    this.pushDataLayer({
      event: 'catalogueSelectItem',
      itemType,
      itemName
    })
  },

  removeFromCatalogue({ itemName, itemType }) {
    this.pushDataLayer({
      event: 'catalogueUnSelectItem',
      itemType,
      itemName
    })
  },

  clearCatalogue(itemsReset) {
    this.pushDataLayer({
      event: 'catalogueResetSelection',
      itemsReset
    })
  },

  catalogueContinueClick() {
    this.pushDataLayer({
      event: 'catalogueContinueButton'
    })
  },

  catalogueAddAll(itemsAdded) {
    this.pushDataLayer({
      event: 'catalogueAddAll',
      itemsAdded
    })
  },

  goToCatalogue() {
    this.pushDataLayer({
      event: 'goToCataloguePage'
    })
  },

  catalogueFieldInput(fieldName, selectionName = '') {
    this.pushDataLayer({
      event: 'catalogueFieldInput',
      fieldName,
      selectionName
    })
  },

  cataloguePerformStep(itemType, amount) {
    this.pushDataLayer({
      event: 'catalogueStep',
      itemType,
      amount
    })
  },

  catalogueCreate({
    filledOutFields,
    totalItemsAdded,
    itemTypesAdded
  }) {
    this.pushDataLayer({
      event: 'catalogueCreate',
      filledOutFields,
      totalItemsAdded,
      itemTypesAdded
    })
  },

  catalogueUpdate({
    filledOutFields,
    totalItemsAdded,
    itemTypesAdded
  }) {
    this.pushDataLayer({
      event: 'save',
      filledOutFields,
      totalItemsAdded,
      itemTypesAdded
    })
  },

  catalogueClicks({
    code: id,
    name,
    list,
    position
  }) {
    const gtmObj = {
      event: 'catalogueClick',
      id,
      name,
      list,
      position
    }

    this.pushDataLayer(gtmObj)
  },

  catalogueEditClick(id) {
    const gtmObj = {
      event: 'catalogInteraction',
      optionType: 'edit',
      id
    }

    this.pushDataLayer(gtmObj)
  },

  removeCatalogue(id) {
    this.pushDataLayer({
      event: 'catalogInteraction',
      optionType: 'delete',
      id
    })
  },

  trackDownloadData() {
    this.pushDataLayer({
      event: 'mySettingsDownloadData'
    })
  },

  trackDeleteAccount() {
    this.pushDataLayer({
      event: 'mySettingsDeleteUserProfile'
    })
  },

  sustainableModalArrowClick(recipeName) {
    this.pushDataLayer({
      event: 'sustainableDietModal',
      recipeName
    })
  },

  sustainableOpenModalClick(recipeName) {
    this.pushDataLayer({
      event: 'sustainableDietClick',
      recipeName
    })
  },

  sustainableRecipeSectionImpression(recipeName) {
    this.pushDataLayer({
      event: 'sustainableDietImpression',
      recipeName
    })
  },

  videoStart(videoTitle) {
    this.pushDataLayer({
      event: 'videoStart',
      videoTitle
    })
  },

  videoProgress({ videoTitle, videoPercent }) {
    this.pushDataLayer({
      event: 'videoProgress',
      videoTitle,
      videoPercent
    })
  },

  videoComplete(videoTitle) {
    this.pushDataLayer({
      event: 'videoComplete',
      videoTitle
    })
  },

  cookToggle(action, recipeTitle) {
    this.pushDataLayer({
      event: 'cookToggle',
      recipeTitle,
      action
    })
  },

  setHem(sfmcHem, placement) {
    this.pushDataLayer({
      event: 'setHem',
      sfmcHem,
      placement
    })
  },

  newsletterSignupGeneral(sfmcHem, placement) {
    this.pushDataLayer({
      event: 'newsletterSignupGeneral',
      sfmcHem,
      placement
    })
  },

  pushDataLayer(obj) {
    if (window.dataLayer) {
      window.dataLayer.push(obj)
    }
  }
}
